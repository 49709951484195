<template>
  <div class="alarm-offline-container">
    <div class="alarm-data-query mt10 ml10">
      {{ showLang('com.date.start') }}：
      <DatePicker v-model="form.start" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      {{ showLang('com.date.end') }}：
      <DatePicker v-model="form.end" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      <Button type="primary" size="default" style="margin-right: 5px" @click="getList(form.index=1)">{{ showLang('com.op.query') }}</Button>
      <Button style="margin-right: 8px" type="primary" @click="exportData">导出</Button>
    </div>
    <div class="offline-data-area mt10" ref="table1">
      <vxe-table :data="list" style="width: 100%;" align="center" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
        <vxe-column type="seq" width="55" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column field="createTime" title="统计时间" width="170"></vxe-column>
        <vxe-colgroup title="站点">
          <vxe-column field="stationTotalCount" title="站点总数"></vxe-column>
          <vxe-column field="offlineStationCount" title="离线报警数"></vxe-column>
          <vxe-column field="powerOffStationCount" title="灭灯报警数"></vxe-column>
          <vxe-column field="voltageUpperStationCount" title="过压报警数"></vxe-column>
          <vxe-column field="voltageLowerStationCount" title="欠压报警数"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="单灯">
          <vxe-column field="lightTotalCount" title="单灯总数"></vxe-column>
          <vxe-column field="offlineLightCount" title="离线数"></vxe-column>
          <vxe-column field="powerOffLightCount" title="灭灯数"></vxe-column>
          <vxe-column field="voltageUpperLightCount" title="过压数"></vxe-column>
          <vxe-column field="voltageLowerLightCount" title="欠压数"></vxe-column>
        </vxe-colgroup>

        <vxe-column width="120" fixed="right" :title="showLang('com.right.nav.operate')">
          <template #default="{ row }">
            <AuthButton opCode="ars" style="margin-right: 5px" @click="showAlarmHisData(row)">{{ showLang('com.data.detail') }}</AuthButton>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :page-sizes='pageSizes' :current-page.sync="form.index" :page-size.sync="form.size" :total="count" @page-change="handlePageChange"> </vxe-pager>
    <ModalAlarmData v-model="showAlarmDataModal" :item="itemData" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalAlarmData from './PowerLineHistoryDesc'
export default {
  name: 'PowerLineHistory',
  components: {
    ModalAlarmData,
  },
  props: {
    args: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      list: [],
      tabHeight: 200,
      count: 0,
      showAlarmDataModal: false,
      itemData: {},
      form: {
        "start": "2024-08-13 00:00:00",
        "end": "2024-08-14 23:59:59",
        "index": 1,
        "size": 20
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels', 'dealAlarmTypes', 'pageSizes', 'pageLayouts']),
    ...mapGetters('common', ['getDealAlarmMethod']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    }
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.todayEnd();
    now.setDate(now.getDate() - 1);
    now.setHours(18, 0, 0, 0);
    this.form.start =  now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    exportData() {
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      this.$store.dispatch('auth/reqFile', {
        title: '线路异常分析历史概要.xlsx',
        url: `//${this.domains.trans}/device/light/ExportPowerLineHistory`,
        args:this.form,
      }).then(() => {
        // this.exportLoading = false;
      });
    },
    showAlarmHisData(el) {
      this.itemData = el;
      this.showAlarmDataModal = true;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.form.index = currentPage
      this.form.size = pageSize
      this.getList()
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight
    },
    getList: function () {
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      this.$axios.post(`//${this.domains.trans}/device/light/QueryPowerLineHistory`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.count = res.data.count;
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-offline-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.offline-data-query {
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.offline-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>